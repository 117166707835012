import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { Link } from "gatsby"
import SEO from "../components/seo"

export const pageQuery = graphql`
  query PrivacyQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      excerpt(pruneLength: 150)
      frontmatter {
        title
      }
    }
  }
`
const PrivacyPage = ({ data }) => {
  if (data.markdownRemark == null) return ""

  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
       <SEO
          title={frontmatter.title}
        />
       <article className="blog-post">
        <header className="featured-banner">
          <section className="article-header">
            <h1>{frontmatter.title}</h1>
            <div></div>
          </section>
        </header>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </article>
      <div className="privacy-strict">
      <Link to="/">Back to Home</Link>
      </div>
      {/* <Link to="/">{props.title}</Link> */}
    </Layout>
  )
}

export default PrivacyPage
